import React, { useState } from "react";

import ReactDOM from "react-dom";
import BodyClassName from "react-body-classname";
import ContactInfo from "../ContactInfo";
import { newsLetterSend } from "../../api/api";

const MessageConfirm = ({ inputEmail }) => {
  return ReactDOM.createPortal(
    <div className="messageConfirm" aria-label="Email confirmation popup">
      <BodyClassName className="confirmed-message" />
      <h2>Thank you</h2>
      <div className="text">
        <p>
          <span>{inputEmail}</span> We will contact you very soon!
        </p>
      </div>
    </div>,
    document.body
  );
};

const ContactForm = ({ header }) => {
  const [inputEmail, setInputEmail] = useState("");
  const [confirmationStatus, setConfirmationStatus] = useState(false);

  const handleEmail = () => (event) => {
    event.preventDefault();
    setInputEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setConfirmationStatus(true);
    const email = inputEmail;

    try {
      const response = await newsLetterSend(email);

      if (response) {
        console.log(response.sid ? "success" : "error", response);
        setTimeout(() => {
          setConfirmationStatus(false);
        }, 1000);
      }
    } catch (error) {
      console.log("handler error");
      setTimeout(() => {
        setConfirmationStatus(false);
      }, 1000);
      return;
    }
  };

  return (
    <div className="form">
      <ContactInfo />
      <h2>Newsletter</h2>
      {confirmationStatus && <MessageConfirm inputEmail={inputEmail} />}
      <form id="form_contact" onSubmit={handleSubmit}>
        {/* <form id="form_contact"> */}
        <div>
          <label htmlFor="email">
            <span className="hidden">E-mail: </span>
          </label>
          <input
            id="inputEmail"
            onChange={handleEmail()}
            value={inputEmail}
            className="input-email"
            type="email"
            name="inputEmail"
            placeholder="Your email *"
            required
          />
        </div>

        <div className="submit-area">
          {/* <span>All fields are mandatory.</span> */}
          <input
            id="email_submit"
            className="submit-button"
            type="submit"
            value="Subscribe"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
