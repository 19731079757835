import React, { useState } from "react";
import {
  Container,
  makeStyles,
  Box,
  useMediaQuery,
  ThemeProvider,
  CssBaseline,
  IconButton,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import ContactInfo from "../ContactInfo";
import Navigation from "../Navigation";
import Logo from "../Logo";

import "./layout.scss";
import "../../styles/form.scss";
import "../../styles/footer.scss";
import "../../styles/messageConfirm.scss";
import { Link } from "gatsby";

import theme from "../../theme/theme";

import italyBg from "../../images/italy-bg.jpg";
import comp1 from "../../images/compressed/comp1.png";
import comp2 from "../../images/compressed/comp2.png";
import comp3 from "../../images/compressed/comp3.png";
import { Helmet } from "react-helmet";
// import { TheatersOutlined } from "@material-ui/icons";

// const bgimages = [italyBg, comp1, comp2, comp3];

// const randombackground = bgimages[Math.floor(Math.random() * bgimages.length)];

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundImage: `url(${comp3})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    backgroundSize: "cover",
    minHeight: "500px",
    height: "100vh",
  },
  backgroundShade: {
    backgroundColor: "#1a2025cc",
    overflowY: "scroll",
    height: "100vh",
  },
  header: {
    backgroundColor: "#000",
    //   backgroundColor: "#181f25",
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    color: "#fff",
  },
}));

function HTMLTags() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    ></Helmet>
  );
}

const Layout = ({ children }) => {
  const classes = useStyles();

  const breakPoint = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  return (
    <ThemeProvider theme={theme}>
      <HTMLTags />
      <CssBaseline />
      <div className={classes.backgroundImage}>
        <div className={classes.backgroundShade}>
          <header className={classes.header}>
            <Container maxWidth="xl" className={classes.container}>
              <div style={{ display: "flex" }}>
                <Link to="/">
                  <Logo header />
                </Link>
              </div>
              {breakPoint ? (
                <>
                  <Navigation />
                  <ContactInfo />
                </>
              ) : (
                <IconButton
                  className={classes.button}
                  aria-label="open menu"
                  component="span"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Container>
          </header>
          <main>
            <Container maxWidth="xl">
              <Box display="flex" flexDirection="column" alignItems="center">
                {children}
              </Box>
            </Container>
          </main>
        </div>
        <Drawer anchor={"right"} open={open} onClose={handleDrawerToggle}>
          <Navigation drawer />
          <ContactInfo drawer />
        </Drawer>
        <div className="dark-background" aria-hidden="true"></div>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
