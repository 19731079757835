import { createMuiTheme } from "@material-ui/core/styles";
// import italyBg from "../images/italy-bg.jpg";

// import comp2 from "../images/compressed/comp2.png";
import comp3 from "../images/compressed/comp3.png";

// const bgimages = [italyBg, comp2, comp3];

// const randombackground = bgimages[Math.floor(Math.random() * bgimages.length)];

const defaultTheme = createMuiTheme({
  palette: {
    white: "#fff",
    black: "#000",
    mainColor: "#ebebeb",
    backgroundLight: "#ebebeb",
    backgroundDark: "#1a2025cc",
  },
});

const theme = createMuiTheme({
  ...defaultTheme,
  customTheme: {
    responsive: {
      display: "block",
      [defaultTheme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          fontFamily: "Arial, Helvetica, sans-serif",
          fontSize: "14px",
          fontWeight: "normal",
          color: "#f3f3f3",
          lineHeight: "20px",
          backgroundColor: "#000",
          [defaultTheme.breakpoints.up("md")]: {
            backgroundSize: "cover",
          },
        },
        h1: {
          margin: "1rem 0",
          color: "#fff",
        },
        h2: {
          margin: "0",
          padding: "0",
          color: "#fff",
          textAlign: "center",
        },
      },
    },
    MuiPaper: {
      root: {
        background: "#000 !important",
      },
    },
  },
});

export default theme;
