import React, { useState } from "react";
// import { Link } from "@material-ui/core";
import { Link } from "gatsby";
import { makeStyles, Modal } from "@material-ui/core";
import { formatter } from "../../utils/utils";
import ContactForm from "../ContactForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignSelf: "center",
  },
  navigation: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& li": {
      fontSize: "1.1em",
      margin: "0 10px",
    },
  },
  link: {
    letterSpacing: "1px",
    color: "#a9a9a9",
    display: "block",
    padding: "10px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      padding: "0",
    },
    // color: "#fff",
  },
}));

const navLinks = ["Home", "Videos", "Music", "Contact"];

const Navigation = ({ drawer }) => {
  const classes = useStyles();

  return (
    <nav className={!drawer ? classes.root : null} aria-label="main">
      <ul className={classes.navigation}>
        {navLinks.map((link, index) => (
          <li key={`${classes.link}-${index}`}>
            <Link
              className={classes.link}
              to={link === "Home" ? "/" : `/${formatter(link)}`}
            >
              {link}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default Navigation;
