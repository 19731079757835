import axios from "axios";

const tokenURL = "https://jspmultimedia.com/api/session/token";
const newsLetterURL =
  "https://jspmultimedia.com/api/webform_rest/submit?_format=json";
const formID = "pantano_newsletter_list";

const getToken = async () => {
  const token = await axios.get(tokenURL, { crossDomain: true });

  return token;
};

export const newsLetterSend = async (email) => {
  try {
    const token = await getToken();
    if (token.status === 200) {
      const csrfToken = token.data;

      const response = await fetch(newsLetterURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          webform_id: formID,
          entity_type: null,
          entity_id: null,
          in_draft: false,
          uri: [`/form/${formID}`],
          email: email,
        }),
      });

      if (response.ok) {
        return response.json();
      }
    }
  } catch (error) {
    console.log("newsLetterSend error", error);
    return error;
  }
};
