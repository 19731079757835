import React from "react";
import logoImg from "../../images/logo-2.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    margin: " 2rem 0",
    alignItems: "center",
    "& img": {
      margin: "1rem 0",
      width: "200px",
    },
  },
  text: {
    letterSpacing: "1px",
    marginLeft: "10px",
    fontSize: "1.2em",
  },
  header: {
    width: "50px !important",
    padding: "4px",
  },
  headerRoot: {
    display: "flex",
    alignItems: "center",
  },
}));

const Logo = ({ header }) => {
  const classes = useStyles();
  return (
    <div className={!header ? classes.root : classes.headerRoot}>
      <img
        src={logoImg}
        alt="Logo, Pantano Music"
        className={header && classes.header}
      />
      {header && <span className={classes.text}>Pantano Music</span>}
      {!header && <h1 className={classes.text}>Pantano Music</h1>}
    </div>
  );
};

export default Logo;
