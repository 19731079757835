import React from "react";
import { makeStyles } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
// import RoomIcon from "@material-ui/icons/Room";
// import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";

const contactInfo = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/musicpantano",
    icon: <FacebookIcon />,
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/pantano.music/",
    icon: <InstagramIcon />,
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com/channel/UCB1xfBxPIYYeh8k6wO5qC2Q",
    icon: <YouTubeIcon />,
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/company/pantano-music",
    icon: <LinkedInIcon />,
  },
  {
    name: "E-mail",
    url: "mailto:pantano@jspmultimedia.com",
    icon: <EmailIcon />,
  },
];

const useStyles = makeStyles(() => ({
  root: {
    alignSelf: "center",
    marginRight: "5px",
    height: "35px",
    "& svg": {
      margin: "0 0 0 0.4rem",
      fontSize: "2em",
      "&:hover, &.focus": {
        transform: "scale(1.2)",
      },
      "&.social-icon ": {
        fontSize: "1.6em",
      },
    },
  },
  list: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  item: {
    marginLeft: "0 10px",
    whiteSpace: "nowrap",
  },
}));

function ContactItem() {
  const classes = useStyles();
  return contactInfo.map((contact) => (
    <li className={classes.item} key={contact.url}>
      <a href={contact.url} target="_blank" rel="noreferrer">
        <span className="hidden">{contact.name}</span>
        {contact.icon}
      </a>
    </li>
  ));
}

const ContactInfo = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className="hidden">Contact Info: </span>
      <ul className={classes.list}>
        <ContactItem />
      </ul>
    </div>
  );
};

export default ContactInfo;
